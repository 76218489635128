import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import DateUtils from "../components/DateUtils"
import BlogPostPreviewImage from "../components/BlogPostPreviewImage"
import CategoryBadge from "../components/Badge"
import MarkdownContent from "./MarkdownContent"
import Layout from "../components/layout/layout"
import TitleHeader from "../components/layout/TitleHeader"
import { ContentContainer, Title, DateLine, BadgeWrapper, ImageContainer } from "../components/layout/ContentLayoutComponents"

const ContentWrapper = styled.div`
  margin-top: 25px;
`

export default ({ data }) => {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  return (
    <Layout>
        <SEO title={title} />
        <Helmet title={title} />

        <ContentContainer>
          <TitleHeader title="Blog"/>
          
          { post.frontmatter.titleImageName && 
            <ImageContainer>
              <BlogPostPreviewImage imageName={post.frontmatter.titleImageName}/>
            </ImageContainer>
          }

          <BadgeWrapper>
            <CategoryBadge categoryName={post.frontmatter.category}></CategoryBadge>
          </BadgeWrapper>

          <Title>{title}</Title>

          <DateLine>
            { DateUtils.formatDate(post.frontmatter.date) }
          </DateLine>

          <ContentWrapper>
            <MarkdownContent htmlData = { post.html } /> 
          </ContentWrapper>

        </ContentContainer>
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        date
        titleImageName
        category
      }
    }
  }
`;
